const MONTH_ABBREVS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function dateFromMsSinceTheEpoch(msSinceEpoch: number) {
  const date = new Date(msSinceEpoch);
  return `${
    MONTH_ABBREVS[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`;
}

export function msToDurationStr(ms: number) {
  const rawSeconds = Math.ceil(ms / 1000);
  const seconds = rawSeconds % 60;
  const minutes = ((rawSeconds - seconds) / 60) % 60;
  const hours = (rawSeconds - seconds - 60 * minutes) / (60 * 60);

  const secondsStr = seconds > 9 ? `${seconds}` : `0${seconds}`;
  const minutesStr = minutes > 9 ? `${minutes}` : `0${minutes}`;
  const hoursStr = hours > 9 ? `${hours}` : `0${hours}`;

  return `${hoursStr}:${minutesStr}:${secondsStr}`;
}
