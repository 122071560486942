import React from "react";
import { DocumentsPageContextProvider } from "./DocumentsPageContext";
import DocumentsPageComponent from "./DocumentsPageComponent";

export default function DocumentsPage() {
  return (
    <DocumentsPageContextProvider>
      <DocumentsPageComponent />
    </DocumentsPageContextProvider>
  );
}
