import { createContext, PropsWithChildren, useContext, useState } from "react";
import { Entity } from "documents/src/entity";
import { listEntitiessUrl } from "../../utils/routes";
import { ApiError, unpackError } from "../../lib/api";
import { mergeRecords } from "../utils/utils";

export interface EntitiesContextContent {
  entities: Entity[];
  errors: { [key: string]: ApiError };
  listEntities: (clientId: string) => void;
  loading: boolean;
}

export const EntitiesContext = createContext<EntitiesContextContent>({
  entities: [],
  errors: {},
  listEntities: (clientId: string) => {},
  loading: false,
});

async function _listEntities() {
  const res = await fetch(listEntitiessUrl(), {
    method: "GET",
    mode: "cors",
  });
  if (res.status === 200) {
    const data = await res.json();
    return {
      status: 200,
      entities: data.entities,
      message: undefined,
    };
  } else {
    return {
      ...(await unpackError(res)),
      entities: undefined,
    };
  }
}

let _loading = false;
export const EntitiesContextProvider = (props: PropsWithChildren<{}>) => {
  const [entities, setEntities] = useState<Entity[]>([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: ApiError }>({});

  async function listEntities(clientId: string) {
    if (_loading) return;
    _loading = true;
    setTimeout(() => setLoading(true), 0);
    const res = await _listEntities();
    const errs = { ...errors };
    if (res.status === 200) {
      setEntities(mergeRecords(entities, res.entities));
      delete errs[clientId];
    } else {
      errs[clientId] = { status: res.status, message: res.message ?? "" };
    }
    setErrors(errs);
    setLoading(false);
    _loading = false;
  }

  return (
    <EntitiesContext.Provider
      value={{
        entities,
        errors,
        listEntities,
        loading,
      }}
    >
      {props.children}
    </EntitiesContext.Provider>
  );
};

export const useEntitiesContext = () => useContext(EntitiesContext);
