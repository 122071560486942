import { StateUsa } from "documents/src/jurisdiction";

export const STATES_USA: { [id: string]: StateUsa } = {
  CALIFORNIA: { id: "CALIFORNIA", label: "California", abbreviation: "CA" },
  TEXAS: { id: "TEXAS", label: "Texas", abbreviation: "TX" },
};

export function getStatesUsa(): StateUsa[] {
  return Object.values(STATES_USA).sort((a, b) =>
    a.label.localeCompare(b.label)
  );
}

export function getStateUsa(id: string): StateUsa {
  switch (id) {
    case "CALIFORNIA":
      return STATES_USA.CALIFORNIA;
    case "TEXAS":
      return STATES_USA.TEXAS;
    default:
      throw new Error(`Unknown state Id: ${id}`);
  }
}
