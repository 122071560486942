import { stringify as stringifyQuery } from "qs";

const { REACT_APP_API_PROTOCOL, REACT_APP_API_HOST, REACT_APP_API_PORT } =
  process.env;
const API_HOST =
  previewApiHost() ||
  `${REACT_APP_API_PROTOCOL}://${REACT_APP_API_HOST}${
    REACT_APP_API_PORT ? `:${REACT_APP_API_PORT}` : ""
  }`;
const DOCUMENTS_API_VERSION = documentsApiVersion();

/** DOCUMENTS */
export function getDocumentUrl(id: string) {
  return `${API_HOST}/${DOCUMENTS_API_VERSION}/documents/${id}`;
}

export interface EntityParam {
  id: string;
  role?: string;
}
// US state.
export interface StateParam {
  id: string;
}
interface ListDocumentsParams {
  cursor?: string;
  pageSize?: string;
  searchPhrase?: string;
  entities?: EntityParam[];
  states?: StateParam[];
}
export function listDocumentsUrl(searchParams: ListDocumentsParams) {
  return `${API_HOST}/${DOCUMENTS_API_VERSION}/documents?${stringifyQuery(
    searchParams
  )}`;
}

/** ENTITIES */

export function listEntitiessUrl() {
  return `${API_HOST}/v1/entities`;
}

/** Helpers */

function previewApiHost(): string | null {
  const apiHostParam = document.location.search
    .replace(/^\?/, "")
    .split("&")
    .find((p) => p.match(/^apiHost=/));
  return apiHostParam ? apiHostParam.split("=")[1] : null;
}

function documentsApiVersion() {
  const docsApiVersionParam = document.location.search
    .replace(/^\?/, "")
    .split("&")
    .find((p) => p.match(/^docsApiVersion=/));
  return docsApiVersionParam ? docsApiVersionParam.split("=")[1] : "v1";
}
