import { Document } from "documents";
import { createContext, PropsWithChildren, useContext, useState } from "react";
import { ApiError, unpackError } from "../../lib/api";
import { getDocumentUrl, listDocumentsUrl } from "../../utils/routes";
import { mergeRecords, shallowIsEqual } from "../utils/utils";

export interface SearchResultSnippets {
  documentId: string;
  searchPhrase: string;
  count: number;
  samples: string[];
}

export interface DocumentsContextContent {
  activeListDocumentsFetches: Set<string>;
  documents: Document[];
  errors: { [key: string]: ApiError };
  getDocument: (id: string) => void;
  listDocuments: (clientId: string, params?: ListDocumentsParams) => void;
  nextCursor: string | null;
  offset: number;
  pageDocumentIds: Set<string>;
  prevCursor: string | null;
  searchResultSnippets: { [key: string]: SearchResultSnippets };
  total: number;
}

export const DocumentsContext = createContext<DocumentsContextContent>({
  activeListDocumentsFetches: new Set<string>(),
  documents: [],
  errors: {},
  getDocument: (id: string) => {},
  listDocuments: (clientId: string, params?: ListDocumentsParams) => {},
  nextCursor: null,
  offset: 0,
  pageDocumentIds: new Set<string>(),
  prevCursor: null,
  searchResultSnippets: {},
  total: 0,
});

// NOTE: no nesting is permitted in this type, to allow for shallow
// compares
interface ListDocumentsParams {
  cursor?: string;
  pageSize?: string;
  searchPhrase?: string;
  entities?: string;
  states?: string;
}

async function _listDocuments(params: ListDocumentsParams) {
  // unserialize entities and states strings
  const urlParams = {
    ...params,
    entities: params.entities ? JSON.parse(params.entities) : undefined,
    states: params.states ? JSON.parse(params.states) : undefined,
  };
  const res = await fetch(listDocumentsUrl(urlParams), {
    method: "GET",
    mode: "cors",
  });
  if (res.status === 200) {
    const data = await res.json();
    return {
      status: 200,
      documents: data.documents,
      message: undefined,
      nextCursor: data.nextCursor,
      offset: data.offset,
      prevCursor: data.prevCursor,
      searchResultSnippets: data.searchResultSnippets,
      total: data.total,
    };
  } else {
    return {
      ...(await unpackError(res)),
      documents: undefined,
      nextCursor: undefined,
      offset: undefined,
      prevCursor: undefined,
      searchResultSnippets: undefined,
      total: undefined,
    };
  }
}

async function _getDocument(id: string) {
  const res = await fetch(getDocumentUrl(id), {
    method: "GET",
    mode: "cors",
  });
  if (res.status === 200) {
    const data = await res.json();
    return {
      status: 200,
      document: data.document,
      message: undefined,
    };
  } else {
    return { ...(await unpackError(res)), document: undefined };
  }
}

let _activeListDocumentsFetches: { [key: string]: ListDocumentsParams } = {};
const _activeGetDocumentFetches = new Set<string>();

export const DocumentsContextProvider = (props: PropsWithChildren<{}>) => {
  const [documents, setDocuments] = useState<Document[]>([]);
  const [pageDocumentIds, setPageDocumentIds] = useState(new Set<string>());
  const [activeListDocumentsFetches, setActiveListDocumentsFetches] = useState(
    new Set<string>()
  );
  const [errors, setErrors] = useState<{ [key: string]: ApiError }>({});
  const [nextCursor, setNextCursor] = useState<string | null>(null);
  const [offset, setOffset] = useState(0);
  const [prevCursor, setPrevCursor] = useState<string | null>(null);
  const [searchResultSnippets, setSearchResultSnippets] = useState<{
    [key: string]: SearchResultSnippets;
  }>({});
  const [total, setTotal] = useState(0);

  async function listDocuments(clientId: string, params?: ListDocumentsParams) {
    if (typeof params === "undefined") params = {};
    if (
      Object.values(_activeListDocumentsFetches).filter((ps) =>
        shallowIsEqual(ps, params ?? {})
      ).length > 0
    ) {
      return;
    }

    _activeListDocumentsFetches[clientId] = params;
    setTimeout(() =>
      setActiveListDocumentsFetches(
        new Set(Object.keys(_activeListDocumentsFetches))
      )
    );
    const res = await _listDocuments(params);
    if (res.status === 200) {
      setDocuments(mergeRecords(documents, res.documents));
      setPageDocumentIds(
        new Set<string>(res.documents.map((doc: Document) => doc.id))
      );
      setSearchResultSnippets(
        res.searchResultSnippets.reduce(
          (
            mem: { [key: string]: SearchResultSnippets },
            snip: SearchResultSnippets
          ) => {
            mem[snip.documentId] = snip;
            return mem;
          },
          {}
        )
      );
      delete _activeListDocumentsFetches[clientId];
      const errs = { ...errors };
      delete errs[clientId];
      setErrors(errs);
      setNextCursor(res.nextCursor);
      setOffset(res.offset ?? 0);
      setPrevCursor(res.prevCursor);
      setTotal(res.total ?? 0);
    } else {
      const errs = { ...errors };
      errs[clientId] = { status: res.status, message: res.message ?? "" };
      setErrors(errs);
    }

    setActiveListDocumentsFetches(
      new Set(Object.keys(_activeListDocumentsFetches))
    );
  }

  async function getDocument(id: string) {
    if (_activeGetDocumentFetches.has(id)) return;
    _activeGetDocumentFetches.add(id);

    const res = await _getDocument(id);
    if (res.status === 200) {
      setDocuments(mergeRecords(documents, [res.document]));
      const errs = { ...errors };
      delete errs[id];
      setErrors(errs);
      _activeGetDocumentFetches.delete(id);
    } else {
      const errs = { ...errors };
      errs[id] = { status: res.status, message: res.message ?? "" };
      setErrors(errs);
    }
  }

  return (
    <DocumentsContext.Provider
      value={{
        activeListDocumentsFetches,
        documents,
        errors,
        getDocument,
        listDocuments,
        nextCursor,
        offset,
        pageDocumentIds,
        prevCursor,
        searchResultSnippets,
        total,
      }}
    >
      {props.children}
    </DocumentsContext.Provider>
  );
};

export const useDocumentsContext = () => useContext(DocumentsContext);
