import "./MultiSelect.css";
import React from "react";
import Select, {
  createFilter,
  Props as ReactSelectProps,
  MultiValue,
  SingleValue,
  ActionMeta,
} from "react-select";

type ClassNamesCallback = (state: unknown) => string;
function buildClassNamesCallback(
  internalFunc: ClassNamesCallback,
  passedFunc?: ClassNamesCallback
) {
  return (state: unknown) => {
    const inputClassNames = passedFunc ? passedFunc(state) : "";
    const internalClassNames = internalFunc(state);
    return `${internalClassNames} ${inputClassNames}`;
  };
}

interface MultiSelectProps<T> extends Omit<ReactSelectProps<T>, "onChange"> {
  onChange: (values: string[]) => void;
}

export function MultiSelect<Option extends { value: string; label: string }>(
  props: MultiSelectProps<Option>
) {
  const { className, onChange } = props;
  const classNames = props.classNames ?? {};
  return (
    <Select
      {...props}
      filterOption={createFilter({
        stringify: (option) => option.label,
      })}
      isMulti
      onChange={(
        newValue: MultiValue<Option> | SingleValue<Option>,
        actionMeta: ActionMeta<Option>
      ) => {
        if (Array.isArray(newValue)) {
          onChange(newValue.map((o) => o.value));
        } else if (newValue !== null && "value" in newValue) {
          onChange([newValue.value]);
        } else {
          onChange([]);
        }
      }}
      className={`MultiSelect${className ? ` ${className}` : ""}`}
      classNames={{
        ...classNames,
        control: buildClassNamesCallback(
          (state: any) =>
            `MultiSelect-control${
              state.isFocused ? " MultiSelect-control-focus" : ""
            }`,
          classNames.control
            ? (classNames.control as ClassNamesCallback)
            : undefined
        ),
        indicatorSeparator: buildClassNamesCallback(
          (state) => "MultiSelect-indicator-separator",
          classNames.indicatorSeparator
            ? (classNames.indicatorSeparator as ClassNamesCallback)
            : undefined
        ),
        input: buildClassNamesCallback(
          (state) => "MultiSelect-input",
          classNames.input
            ? (classNames.input as ClassNamesCallback)
            : undefined
        ),
        menu: buildClassNamesCallback(
          (state) => "MultiSelect-menu",
          classNames.menu ? (classNames.menu as ClassNamesCallback) : undefined
        ),
        multiValue: buildClassNamesCallback(
          (state) => "MultiSelect-multi-value",
          classNames.multiValue
            ? (classNames.multiValue as ClassNamesCallback)
            : undefined
        ),
        multiValueLabel: buildClassNamesCallback(
          (state) => "MultiSelect-multi-value-label",
          classNames.multiValueLabel
            ? (classNames.multiValueLabel as ClassNamesCallback)
            : undefined
        ),
        multiValueRemove: buildClassNamesCallback(
          (state) => "MultiSelect-multi-value-remove",
          classNames.multiValueRemove
            ? (classNames.multiValueRemove as ClassNamesCallback)
            : undefined
        ),
        option: buildClassNamesCallback(
          (state: any) =>
            `MultiSelect-option${
              state.isFocused ? " MultiSelect-option-focus" : ""
            }`,
          classNames.option
            ? (classNames.option as ClassNamesCallback)
            : undefined
        ),
      }}
    />
  );
}
