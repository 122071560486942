import {
  Badge,
  Box,
  Flex,
  HStack,
  IconButton,
  Spacer,
  useColorMode,
} from "@chakra-ui/react";
import React from "react";
import { Moon, Sun } from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/logo512.png";
import Link from "../Link/Link";
import "./Header.css";

export default function Header() {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <div className="Header">
      <div className="Header-inner">
        <Flex>
          <Box>
            <Link to="/">
              <img
                alt="CivicMinutes logo"
                height="40"
                width="40"
                src={logo}
              />
            </Link>
          </Box>
          <Spacer></Spacer>
          <Box alignSelf="center" mr={1}>
            <nav className="Header-nav">
              <NavLink className="Header-nav-link" to="/documents">
                Transcripts
              </NavLink>
            </nav>
          </Box>
          <HStack>
            <IconButton
              aria-label="Change theme button"
              icon={colorMode === "dark" ? <Sun /> : <Moon />}
              onClick={toggleColorMode}
              variant="subtle"
            />
            <Badge colorScheme="green" fontSize="1rem">
              PREVIEW
            </Badge>
          </HStack>
        </Flex>
      </div>
    </div>
  );
}
