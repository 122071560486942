import {
  Box,
  Card,
  CardBody,
  Container,
  Flex,
  Heading,
  IconButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Document } from "documents";
import React from "react";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import { SearchResultSnippets } from "../../contexts/documents/documents";
import Alert from "../Alert/Alert";
import DocumentMetadataCard from "../DocumentMetadataCard/DocumentMetadataCard";
import DocumentsFilterForm, {
  DocumentsFilterFormState,
} from "../DocumentsFilterForm/DocumentsFilterForm";
import Link from "../Link/Link";
import { Page } from "../Page/Page";
import { PreviewNotice } from "../PreviewNotice/PreviewNotice";
import SignupCard from "../SignupCard/SignupCard";
import "./DocumentsPage.css";
import { useDocumentsPageContext } from "./DocumentsPageContext";

const SIGNUP_INJECT_POINT = 5;

export default function DocumentsPageComponent() {
  const {
    changePage,
    currentUser,
    documents,
    error,
    loading,
    nextCursor,
    offset,
    onFilter,
    pageSize,
    prevCursor,
    publishers,
    states,
    searchPhrase,
    searchResultSnippets,
    selectedPublisherIds,
    selectedStateIds,
    total,
  } = useDocumentsPageContext();

  if (error) {
    return (
      <Page className="DocumentsPage">
        <Alert status="error" alertTitle="Failed to load documents" />
      </Page>
    );
  }

  const preSignupDocs = documents.slice(0, SIGNUP_INJECT_POINT);
  const postSignupDocs = documents.slice(SIGNUP_INJECT_POINT, documents.length);

  return (
    <Page className="DocumentsPage" loading={loading}>
      <Container maxWidth="container.lg" p={0}>
        <VStack>
          <Box w="100%" marginBottom="8">
            <Heading mb={2}>CivicMinutes</Heading>
            <Text mb={5} ml={0.5} mt={5} size="sm">
              A search engine for state legislative hearings.
            </Text>
            <PreviewNotice />

            <Card mt={8} w="100%">
              <CardBody>
                {publishers && publishers.length > 0 && (
                  <DocumentsFilterForm
                    initialSearchPhrase={searchPhrase}
                    initialPublishers={selectedPublisherIds}
                    initialStates={selectedStateIds}
                    loading={loading}
                    onSubmit={(filters: DocumentsFilterFormState) =>
                      onFilter(filters.searchPhrase, filters.publishers, filters.states)
                    }
                    publishers={publishers}
                    states={states}
                  />
                )}
              </CardBody>
            </Card>
          </Box>

          <Box justifyContent="right" w="100%">
            <Pagination
              docsToRender={documents}
              loading={loading}
              offset={offset}
              onNextPage={() => changePage(nextCursor)}
              onPreviousPage={() => changePage(prevCursor)}
              pageSize={pageSize}
              total={total}
            />
          </Box>

          {documents.length === 0 && (
            <Box
              className="DocumentsPage-no-docs-message"
              w="100%"
              p={12}
              textAlign="center"
              justifyContent="center"
            >
              <Text fontSize="lg">No matching documents</Text>
            </Box>
          )}

          <Box w="100%">
            {preSignupDocs.map((doc) => (
              <DocumentResult
                doc={doc}
                key={`doc-list-${doc.id}`}
                lastDoc={documents.indexOf(doc) === documents.length - 1}
                searchResultSnippets={searchResultSnippets[doc.id] ?? undefined}
              />
            ))}
            {currentUser && (
              <SignupCard mb={10} mt={10} pb={3} user={currentUser} />
            )}
            {postSignupDocs.map((doc) => (
              <DocumentResult
                doc={doc}
                key={`doc-list-${doc.id}`}
                lastDoc={documents.indexOf(doc) === documents.length - 1}
                searchResultSnippets={searchResultSnippets[doc.id] ?? undefined}
              />
            ))}
          </Box>

          <Box justifyContent="right" w="100%">
            <Pagination
              docsToRender={documents}
              loading={loading}
              offset={offset}
              onNextPage={() => changePage(nextCursor)}
              onPreviousPage={() => changePage(prevCursor)}
              pageSize={pageSize}
              total={total}
            />
          </Box>
        </VStack>
      </Container>
    </Page>
  );
}

interface DocumentResultProps {
  doc: Document;
  lastDoc: boolean;
  searchResultSnippets?: SearchResultSnippets;
}
function DocumentResult(props: DocumentResultProps) {
  const { doc, lastDoc, searchResultSnippets } = props;
  return (
    <Box mb={lastDoc ? 0 : 5} className="DocumentsPage-doc-link">
      <Link to={`/documents/${doc.id}`}>
        <DocumentMetadataCard
          document={doc}
          searchResultSnippets={searchResultSnippets}
        />
      </Link>
    </Box>
  );
}

interface PaginationProps {
  docsToRender: Document[];
  loading: boolean;
  onNextPage: () => void;
  onPreviousPage: () => void;
  offset: number;
  pageSize: number;
  total: number;
}

function Pagination(props: PaginationProps) {
  const {
    docsToRender,
    loading,
    onNextPage,
    onPreviousPage,
    pageSize,
    offset,
    total,
  } = props;

  return (
    <Flex
      className="Pagination"
      alignItems="center"
      justifyContent="right"
      gap={4}
      w="100%"
    >
      <Text className="Pagination-results-tracker">
        Results {docsToRender.length < 1 ? 0 : offset + 1} -{" "}
        {offset + docsToRender.length} of {total}
      </Text>
      <IconButton
        aria-label="Previous page"
        isDisabled={loading || docsToRender.length === 0 || offset === 0}
        icon={<ChevronLeft />}
        onClick={onPreviousPage}
      />
      <IconButton
        aria-label="Next page"
        isDisabled={
          loading || docsToRender.length === 0 || offset + pageSize >= total
        }
        icon={<ChevronRight />}
        onClick={onNextPage}
      />
    </Flex>
  );
}
