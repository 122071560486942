import React, { PropsWithChildren } from "react";
import "./Alert.css";
import {
  Alert as ChakraAlert,
  AlertProps as ChakraAlertProps,
  AlertTitle,
  AlertDescription,
  Box,
  Flex,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { ExclamationCircle, InfoCircle } from "react-bootstrap-icons";

interface AlertProps extends ChakraAlertProps {
  action?: React.ReactNode;
  layout?: "line" | "box";
  alertTitle: string | React.ReactNode;
}

export default function Alert(props: AlertProps) {
  const layout = props.layout || "line";
  const { children, className, status } = props;
  const classes = `Alert Alert-${status}${className ? ` ${className}` : ""}`;
  const passAlongProps = {
    ...props,
    className: classes,
  };
  delete passAlongProps.action;
  delete passAlongProps.alertTitle;
  return (
    <ChakraAlert {...passAlongProps}>
      {layout === "line" ? (
        <LineLayout {...props}>{children}</LineLayout>
      ) : (
        <BoxLayout {...props}>{children}</BoxLayout>
      )}
    </ChakraAlert>
  );
}

function AlertIcon({ status }: { status?: string }) {
  switch (status) {
    case "error":
      return <ExclamationCircle className="Alert-icon" />;
    default:
      return <InfoCircle className="Alert-icon" />;
  }
}

function BoxLayout(props: PropsWithChildren<AlertProps>) {
  return (
    <VStack>
      <HStack alignItems="flex-start" gap={2}>
        <Box mt={1}>
          <AlertIcon status={props.status} />
        </Box>
        <Box>
          <AlertTitle mb={2}>{props.alertTitle}</AlertTitle>
          <AlertDescription>{props.children}</AlertDescription>
        </Box>
      </HStack>
      {!props.action ? (
        ""
      ) : (
        <Box className="Alert-action" content="right" width="100%">
          {props.action}
        </Box>
      )}
    </VStack>
  );
}

function LineLayout(props: PropsWithChildren<AlertProps>) {
  return (
    <Flex alignItems="center" width="100%">
      <Box pr={2}>
        <AlertIcon status={props.status} />
      </Box>
      <Box>
        <AlertTitle>{props.alertTitle}</AlertTitle>
      </Box>
      <Box flexGrow={2}>
        <AlertDescription>{props.children}</AlertDescription>
      </Box>
      {!props.action ? "" : <Box className="Alert-action">{props.action}</Box>}
    </Flex>
  );
}
