import { Button, Flex, FormControl, Input } from "@chakra-ui/react";
import { Entity } from "documents/src/entity";
import { StateUsa } from "documents/src/jurisdiction";
import React, { useState } from "react";
import { MultiSelect } from "../MultiSelect/MultiSelect";

export interface DocumentsFilterFormState {
  searchPhrase: string;
  publishers: string[];
  states: string[];
}

const DEFAULT_FORM_STATE: DocumentsFilterFormState = {
  searchPhrase: "",
  publishers: [],
  states: [],
};

interface DocumentsFilterFormProps {
  initialPublishers?: string[];
  initialStates?: string[];
  initialSearchPhrase?: string;
  loading: boolean;
  onSubmit: (formData: DocumentsFilterFormState) => void;
  publishers: Entity[];
  states: StateUsa[];
}

export default function DocumentsFilterForm(props: DocumentsFilterFormProps) {
  const {
    initialPublishers,
    initialStates,
    initialSearchPhrase,
    loading,
    onSubmit,
    publishers,
    states,
  } = props;
  const publisherOptions = publishers.map((p) => ({
    value: p.id,
    label: p.label,
  }));
  const stateOptions = states.map((s) => ({
    value: s.id,
    label: s.label,
  }));
  const initialFormState = { ...DEFAULT_FORM_STATE };
  if (initialSearchPhrase) initialFormState.searchPhrase = initialSearchPhrase;
  let defaultPublishers;
  if (initialPublishers) {
    initialFormState.publishers = initialPublishers;
    const pubIds = new Set<string>(initialPublishers);
    defaultPublishers = publisherOptions.filter((po) => pubIds.has(po.value));
  }
  let defaultStates;
  if (initialStates) {
    initialFormState.states = initialStates;
    const stateIds = new Set<string>(initialStates);
    defaultStates = stateOptions.filter((so) => stateIds.has(so.value));
  }

  const [formState, setFormState] =
    useState<DocumentsFilterFormState>(initialFormState);
  const [lastSubmittedState, setLastSubmittedState] =
    useState<DocumentsFilterFormState>(initialFormState);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setLastSubmittedState(formState);
        onSubmit(formState);
      }}
    >
      <Flex
        alignItems="start"
        direction={{ base: "column", md: "row" }}
        gap={3}
        w="100%"
      >
        <FormControl width="70%">
          <MultiSelect
            onChange={(values: string[]) =>
              setFormState({ ...formState, states: values })
            }
            placeholder="Select state..."
            options={stateOptions}
            defaultValue={defaultStates}
          />
        </FormControl>
        <FormControl>
          <MultiSelect
            onChange={(values: string[]) =>
              setFormState({ ...formState, publishers: values })
            }
            placeholder="Select committee..."
            options={publisherOptions}
            defaultValue={defaultPublishers}
          />
        </FormControl>
        <FormControl>
          <Input
            onChange={(e) =>
              setFormState({
                ...formState,
                searchPhrase: e.target.value,
              })
            }
            placeholder="Search..."
            value={formState.searchPhrase}
          />
        </FormControl>
        <Button
          colorScheme="purple"
          isDisabled={
            JSON.stringify(formState) === JSON.stringify(lastSubmittedState) ||
            (formState.searchPhrase.length !== 0 &&
              formState.searchPhrase.length < 3)
          }
          isLoading={loading}
          type="submit"
          w={{ base: "100%", md: "xs" }}
        >
          Filter
        </Button>
      </Flex>
    </form>
  );
}
