export interface ApiError {
  status: number;
  message: string;
}

export async function unpackError(res: Response) {
  let err = { message: "Something went wrong" };
  try {
    err = await res.json();
  } catch (e) {
    // do nothing
  }
  return {
    status: res.status,
    message: err.message ?? "",
  };
}
