import React, { PropsWithChildren } from "react";
import { Box, Progress } from "@chakra-ui/react";
import "./Page.css";
import Header from "../Header/Header";

interface PageProps {
  className?: string;
  loading?: boolean;
  noPad?: boolean;
}

export function Page(props: PropsWithChildren<PageProps>) {
  return (
    <div className={`Page ${props.className}`}>
      <Header />
      {props.loading ? (
        <Progress
          className="Page-loader"
          size="xs"
          isIndeterminate
          zIndex={2}
        />
      ) : null}
      <Box m="0 auto" className={`Page-inner${props.noPad ? " no-pad" : ""}`}>
        {props.children}
      </Box>
    </div>
  );
}
