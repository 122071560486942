import { Box, Button, Container, Heading, Stack, Text } from "@chakra-ui/react";
import { EntityDocumentView, Role, TranscriptSegment } from "documents";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useCurrentUserContext } from "../../contexts/currentUser/currentUser";
import { useDocumentsContext } from "../../contexts/documents/documents";
import { msToDurationStr } from "../../utils/time";
import Alert from "../Alert/Alert";
import DocumentMetadataCard from "../DocumentMetadataCard/DocumentMetadataCard";
import Link from "../Link/Link";
import { Page } from "../Page/Page";
import SignupCard from "../SignupCard/SignupCard";
import "./DocumentPage.css";

const UKNOWN_SPEAKER_LABEL = "Unknown Speaker";
const PRE_INTERSTITIAL_SEGMENT_COUNT = 3;

function speakerRole(speaker: EntityDocumentView) {
  return speaker.role === Role.MEMBER
    ? null
    : speaker.role?.split("_").join(" ").toLowerCase() ?? "";
}

export default function DocumentPage() {
  const { id } = useParams();
  const { hash } = useLocation();
  const { documents, getDocument, errors } = useDocumentsContext();
  const { user } = useCurrentUserContext();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const doc = documents.find((d) => d.id === id);

  useEffect(() => {
    if (!hash || !doc) return;
    const el = document.querySelector(`${hash}`);
    if (el)
      setTimeout(
        () => el.scrollIntoView({ behavior: "smooth", block: "center" }),
        50
      );
  }, [doc, hash]);

  if (!doc || !doc.content || doc.content.length < 1) {
    if (errors[id ?? ""] || loaded) {
      return (
        <Page className="DocumentPage">
          <Alert
            action={
              <Link to="/documents">
                <Button colorScheme="purple">Documents</Button>
              </Link>
            }
            status="error"
            alertTitle="Document not found"
          />
        </Page>
      );
    } else if (!loading && !loaded) {
      setLoading(true);
      getDocument(id ?? "");
    }
    return <Page className="DocumentPage" loading={true} />;
  } else if (loading) {
    setLoaded(true);
    setLoading(false);
  }

  const entitiesById = doc.entities.reduce((mem, e) => {
    mem[e.id] = e;
    return mem;
  }, {} as { [key: string]: EntityDocumentView });

  const preInterstitialContent = doc.content.slice(
    0,
    PRE_INTERSTITIAL_SEGMENT_COUNT
  );
  const postInterstitialContent = doc.content.slice(
    PRE_INTERSTITIAL_SEGMENT_COUNT,
    doc.content.length
  );

  return (
    <Page className="DocumentPage">
      <Container maxWidth="container.lg" p={0}>
        <DocumentMetadataCard document={doc} showEntities />
        {preInterstitialContent.map((segment) => (
          <Segment
            entitiesById={entitiesById}
            key={`segment-${segment.startMs}-${segment.speakerId}-${segment.content.length}`}
            segment={segment}
          />
        ))}
        {user && (
          <SignupCard mb={14} ml={{ sm: 0, md: "96px" }} mt={10} user={user} />
        )}
        {postInterstitialContent.map((segment) => (
          <Segment
            entitiesById={entitiesById}
            key={`segment-${segment.startMs}-${segment.speakerId}-${segment.content.length}`}
            segment={segment}
          />
        ))}
        <Warning />
      </Container>
    </Page>
  );
}

interface SegmentProps {
  entitiesById: { [key: string]: EntityDocumentView };
  segment: TranscriptSegment;
}

function Segment(props: SegmentProps) {
  const { entitiesById, segment } = props;
  return (
    <Stack
      id={`segment-${segment.startMs}`}
      mt={{ base: 8, md: 6 }}
      gap={{ base: 0, md: 6 }}
      direction={{ base: "column", md: "row" }}
    >
      <Box>
        <Link to={`#segment-${segment.startMs}`} variant="secondary">
          <Text lineHeight="md">{msToDurationStr(segment.startMs)}</Text>
        </Link>
      </Box>
      <Box>
        {segment.speakerId && entitiesById[segment.speakerId] ? (
          <SpeakerMeta speaker={entitiesById[segment.speakerId]} />
        ) : (
          <Heading as="h4" size="md" mb={2}>
            {UKNOWN_SPEAKER_LABEL}
          </Heading>
        )}
        <Text
          fontSize="lg"
          lineHeight={7}
          textAlign={{ base: "left", sm: "justify" }}
        >
          {segment.content}
        </Text>
      </Box>
    </Stack>
  );
}

function SpeakerMeta(props: { speaker: EntityDocumentView }) {
  const { speaker } = props;
  const { affiliation, affiliationLink, affiliationRole, role } = speaker;
  const roleLabel = speakerRole(speaker);

  let displayRole: string | null = null;
  if (
    roleLabel &&
    roleLabel.split("_").join(" ").toLowerCase() !==
      affiliationRole?.toLowerCase()
  ) {
    displayRole = roleLabel;
  }
  return (
    <Box>
      <Heading as="h4" size="md" mb={1}>
        {speaker.label}
      </Heading>
      {(!!affiliation || !!role) && (
        <Text className="SpeakerMeta-role" fontSize="sm" mb={2}>
          {displayRole}
          {displayRole && affiliation ? " • " : ""}
          {affiliation
            ? affiliationRole
              ? `${affiliationRole} @ ${affiliation}`
              : affiliation
            : ""}
          {(displayRole || affiliation) && affiliationLink ? " • " : ""}
          {affiliationLink ? (
            <Link
              className="SpeakerMeta-link"
              to={affiliationLink}
              target="_blank"
            >
              Profile
            </Link>
          ) : (
            ""
          )}
        </Text>
      )}
    </Box>
  );
}

function Warning() {
  return (
    <Alert
      alertTitle={<Text fontSize="l">Warning</Text>}
      layout="box"
      mt={10}
      status="warning"
    >
      <Text mb={2} mt={4}>
        This transcript was generated automatically and may contain
        transcription errors, misidentified speakers, or missing metadata (eg.
        affiliation).
      </Text>
      <Text mb={2} mt={4}>
        If you'd like to request a correction, you can shoot us an{" "}
        <Link to="mailto:hardik@oneoverone.io">
          <Text as="span" fontWeight="bold">
            email
          </Text>
        </Link>
        .
      </Text>
    </Alert>
  );
}
