import { ListItem, Text, UnorderedList } from "@chakra-ui/react";
import React from "react";
import Alert from "../Alert/Alert";
import Link from "../Link/Link";

export function PreviewNotice() {
  return (
    <Alert
      status="info"
      alertTitle={<Text fontSize="xl">This is a preview </Text>}
      layout="box"
    >
      <Text mb={2} mt={4}>
        This is a demo version of a larger application we're building to help
        organize and access information contained in government hearings.
      </Text>
      <Text mb={2} mt={4} fontSize="l" fontWeight="bold">
        Disclaimer
      </Text>
      <UnorderedList mb={2} mt={4}>
        <ListItem>
          This preview is limited to a subset of California and Texas state
          sessions.
        </ListItem>
        <ListItem>
          Transcripts are not perfect and some session participants may be
          misidentified or lack metadata (eg. affiliation).
        </ListItem>
        <ListItem>
          This preview is running on a tiny server, so searches can take 15
          seconds or longer.
        </ListItem>
      </UnorderedList>
      <Text mb={2} mt={4} fontSize="l" fontWeight="bold">
        Support
      </Text>
      <Text mb={2} mt={4}>
        If you have any questions, comments, or feature requests, you can shoot
        us an{" "}
        <Link to="mailto:hardik@oneoverone.io">
          <Text as="span" fontWeight="bold">
            email
          </Text>
        </Link>
        .
      </Text>
    </Alert>
  );
}
