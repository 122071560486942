import {
  Box,
  Card,
  CardBody,
  Flex,
  Highlight,
  Show,
  Text,
} from "@chakra-ui/react";
import { ContentType, Document, EntityDocumentView, Role } from "documents";
import pluralize from "pluralize";
import React from "react";
import { BlockquoteLeft, BoxArrowUpRight } from "react-bootstrap-icons";
import { SearchResultSnippets } from "../../contexts/documents/documents";
import { getStateUsa } from "../../contexts/jurisdiction/jurisdiction";
import { dateFromMsSinceTheEpoch, msToDurationStr } from "../../utils/time";
import Link from "../Link/Link";
import "./DocumentMetadataCard.css";

interface DocumentMetadataCardProps {
  document: Document;
  searchResultSnippets?: SearchResultSnippets;
  showEntities?: boolean;
}

export default function DocumentMetadataCard(props: DocumentMetadataCardProps) {
  const doc = props.document;
  const jurisdiction = doc.jurisdictions.find((j) => j.geography.state);
  const stateAbbrev =
    jurisdiction && jurisdiction.geography.state
      ? getStateUsa(jurisdiction.geography.state).abbreviation
      : null;
  const { searchResultSnippets } = props;
  return (
    <Card className="DocumentMetadataCard" key={`doc-list-${doc.id}`}>
      <CardBody>
        <Flex gap={3} direction={{ base: "column", md: "row" }}>
          <Show above="lg">
            <Box>
              <BlockquoteLeft className="DocumentMetadataCard-doc-icon" />
            </Box>
          </Show>
          <Box flex="2">
            <Flex gap={2} direction={{ base: "column", md: "row" }}>
              {stateAbbrev && <Box flex="1">
                <Text fontSize="xs" fontWeight={900}>
                  State
                </Text>
                <Text noOfLines={2}>{stateAbbrev}</Text>
              </Box>}

              <Box flex="4">
                <Text fontSize="xs" fontWeight={900}>
                  Name
                </Text>
                <Text noOfLines={2}>{doc.label}</Text>
              </Box>

              <Box flex="1">
                <Text fontSize="xs" fontWeight={900}>
                  Date
                </Text>
                <Text noOfLines={2}>
                  {dateFromMsSinceTheEpoch(doc.publishDateMs)}
                </Text>
              </Box>
              <Box flex="1">
                <Text fontSize="xs" fontWeight={900}>
                  Duration
                </Text>
                <Text noOfLines={1}>{msToDurationStr(doc.duration)}</Text>
              </Box>
            </Flex>
            {searchResultSnippets && (
              <Box mt={4}>
                <Text fontSize="xs" fontWeight={900}>
                  {searchResultSnippets.count}{" "}
                  {searchResultSnippets.count > 1 ? "Matches" : "Match"}
                </Text>
                {searchResultSnippets.samples.map((sample, index) => (
                  <Box
                    className="DocumentMetadataCard-search-result-match"
                    key={`${doc.id}-sample-${index}`}
                    mt={2}
                  >
                    <Highlight
                      query={searchResultSnippets.searchPhrase}
                      styles={{
                        color: "purple.400",
                      }}
                    >
                      {`${
                        sample.charAt(0) === sample.charAt(0).toUpperCase()
                          ? ""
                          : "..."
                      }${sample}...`}
                    </Highlight>
                  </Box>
                ))}
              </Box>
            )}
            {props.showEntities &&
              doc.entities.find((e) => e.role !== Role.PUBLISHER) && (
                <Box mt={4}>
                  <SpeakerList document={doc} />
                </Box>
              )}
          </Box>
        </Flex>
      </CardBody>
    </Card>
  );
}

const OTHER_LABEL = "other";
const KEY_ROLES_BY_CONTENT_TYPE = new Map<
  ContentType,
  { [key: string]: Set<Role> }
>([
  [
    ContentType.TRANSCRIPT,
    {
      legislator: new Set([
        Role.CHAIR,
        Role.COMMITTEE_MEMBER,
        Role.MEMBER,
        Role.REPRESENTATIVE,
        Role.SENATOR,
        Role.VICE_CHAIR,
      ]),
      witness: new Set([Role.WITNESS]),
    },
  ],
]);
const ROLES_WITH_AFFILATIONS = new Set([Role.WITNESS]);
interface SpeakerListProps {
  document: Document;
}

function affiliationSuffix(label: string, entity: EntityDocumentView) {
  if (entity.role) {
    if (label === OTHER_LABEL) {
      return ` (${entity.role.split("_").join(" ").toLowerCase()})`;
    } else if (ROLES_WITH_AFFILATIONS.has(entity.role) && entity.affiliation) {
      return ` (${entity.affiliation})`;
    }
  }
  return "";
}

function SpeakerList(props: SpeakerListProps) {
  const { document } = props;
  const keyRoles = KEY_ROLES_BY_CONTENT_TYPE.get(document.contentType) ?? {};
  const entitiesByRoleLabel: { [key: string]: EntityDocumentView[] } =
    document.entities
      .filter((e: EntityDocumentView) => e.role !== Role.PUBLISHER)
      .reduce((mem: { [key: string]: EntityDocumentView[] }, e) => {
        let roleLabel = OTHER_LABEL;
        for (const [label, roles] of Object.entries(keyRoles)) {
          if (roles.has(e.role)) {
            roleLabel = label;
            break;
          }
        }
        if (!mem[roleLabel]) mem[roleLabel] = [];
        mem[roleLabel].push(e);
        return mem;
      }, {});
  const orderedLabels = Object.keys(keyRoles).concat(OTHER_LABEL);

  return (
    <>
      <Text fontSize="xs" fontWeight={900}>
        {document.contentType === ContentType.TRANSCRIPT
          ? "Speakers"
          : "Participants"}
      </Text>
      {orderedLabels.map((label) => {
        const entities = entitiesByRoleLabel[label];
        return (
          label &&
          entities && (
            <Text
              className="SpeakerList-role"
              key={label}
              mt={2}
              textAlign="justify"
            >
              <strong>{pluralize(label)}</strong>:{" "}
              {entities.map((e, idx) => (
                <span key={`SpeakerList-entity-${e.id}`}>
                  {ROLES_WITH_AFFILATIONS.has(e.role) && e.affiliationLink ? (
                    <Link to={e.affiliationLink} target="_blank">
                      {e.label}
                      <BoxArrowUpRight
                        className="SpeakerLink-affiliation-link-icon"
                        display="inline-block"
                      />
                    </Link>
                  ) : (
                    e.label
                  )}
                  {affiliationSuffix(label, e)}
                  {idx < entities.length - 1 ? ", " : ""}
                </span>
              ))}
            </Text>
          )
        );
      })}
    </>
  );
}
