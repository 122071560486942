import React, { PropsWithChildren } from "react";
import "./Link.css";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";

export enum LinkVariant {
  SECONDARY = "secondary",
}

interface LinkProps extends RouterLinkProps {
  variant?: string;
}

export default function Link(props: PropsWithChildren<LinkProps>) {
  const { className, variant } = props;
  const classes = `Link${className ? ` ${className}` : ""}${
    variant ? ` ${variant}` : ""
  }`;

  return (
    <ChakraLink as={"span"} className={classes}>
      <RouterLink {...{ ...props, className: undefined, variant: undefined }}>
        {props.children}
      </RouterLink>
    </ChakraLink>
  );
}
