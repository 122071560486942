export function shallowIsEqual<T extends Object>(a: T, b: T) {
  const aKeys = Object.keys(a) as (keyof typeof a)[];
  if (aKeys.length !== Object.keys(b).length) {
    return false;
  }

  for (const key of aKeys) {
    if (a[key] !== b[key]) return false;
  }

  return true;
}

interface RecordWithId {
  id: any;
}
export function mergeRecords<T extends RecordWithId>(
  base: T[],
  additional: T[]
) {
  const baseRecs = base.reduce((mem: { [key: number]: T }, doc: T) => {
    mem[doc.id] = doc;
    return mem;
  }, {});
  const additionalRecs = additional.reduce(
    (mem: { [key: number]: T }, doc: T) => {
      mem[doc.id] = doc;
      return mem;
    },
    {}
  );
  const baseIds = new Set(base.map((d: T) => d.id));
  const additionalIds = new Set(additional.map((d: T) => d.id));
  const ids = new Set(Array.from(baseIds).concat(Array.from(additionalIds)));

  return Array.from(ids).reduce((mem: T[], id: number) => {
    if (baseIds.has(id) && additionalIds.has(id)) {
      mem.push({ ...baseRecs[id], ...additionalRecs[id] });
    } else if (baseIds.has(id)) {
      mem.push(baseRecs[id]);
    } else {
      mem.push(additionalRecs[id]);
    }
    return mem;
  }, []);
}
