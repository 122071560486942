import { Document, Role } from "documents";
import { Entity } from "documents/src/entity";
import { StateUsa } from "documents/src/jurisdiction";
import { parse as parseQueryString } from "qs";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import {
  User,
  useCurrentUserContext,
} from "../../contexts/currentUser/currentUser";
import {
  SearchResultSnippets,
  useDocumentsContext,
} from "../../contexts/documents/documents";
import { useEntitiesContext } from "../../contexts/entities/entities";
import { getStatesUsa } from "../../contexts/jurisdiction/jurisdiction";
import { EntityParam, StateParam } from "../../utils/routes";

const DEFAULT_PAGE_SIZE = 30;
const ENTITIES_CLIENT_ID = "documents-page";

export interface DocumentsPageContextContent {
  changePage: (cursor: string | null) => void;
  currentUser: User | null;
  documents: Document[];
  error?: string;
  loading: boolean;
  nextCursor: string | null;
  offset: number;
  onFilter: (
    newSearchPhrase: string,
    newPublishers: string[],
    newStates: string[]
  ) => void;
  pageSize: number;
  prevCursor: string | null;
  publishers: Entity[];
  states: StateUsa[];
  searchPhrase: string;
  searchResultSnippets: {
    [key: string]: SearchResultSnippets;
  };
  selectedPublisherIds: string[];
  selectedStateIds: string[];
  total: number;
}

export const DocumentsPageContext = createContext<DocumentsPageContextContent>({
  changePage: (cursor: string | null) => {},
  currentUser: null,
  documents: [],
  loading: false,
  offset: 0,
  onFilter: (
    newSearchPhrase: string,
    newPublishers: string[],
    newStates: string[]
  ) => {},
  pageSize: DEFAULT_PAGE_SIZE,
  publishers: [],
  states: [],
  nextCursor: null,
  prevCursor: null,
  searchPhrase: "",
  searchResultSnippets: {},
  selectedPublisherIds: [],
  selectedStateIds: [],
  total: 0,
});

export const DocumentsPageContextProvider = (props: PropsWithChildren<{}>) => {
  const { user: currentUser } = useCurrentUserContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    activeListDocumentsFetches,
    documents,
    errors: documentsErrors,
    listDocuments,
    nextCursor,
    offset,
    pageDocumentIds,
    prevCursor,
    searchResultSnippets,
    total,
  } = useDocumentsContext();
  const {
    entities,
    errors: entitiesErrors,
    listEntities,
    loading: entitiesLoading,
  } = useEntitiesContext();
  const [loading, setLoading] = useState(false);
  const pageSize = parseInt(
    searchParams.get("pageSize") ?? `${DEFAULT_PAGE_SIZE}`
  );
  const [hasSeenEntitiesLoading, setHasSeenEntitiesLoading] = useState(false);
  const [hasSeenDocumentsClientId, setHasSeenDocumentsClientId] =
    useState(false);
  const queryObject = parseQueryString(searchParams.toString());
  const queryEntities: EntityParam[] = queryObject.entities
    ? (queryObject.entities as unknown as EntityParam[])
    : [];
  const entitiesJson = queryObject.entities
    ? JSON.stringify(queryObject.entities)
    : null;
  const queryStates: StateParam[] = queryObject.states
    ? (queryObject.states as unknown as StateParam[])
    : [];
  const statesJson = queryObject.states
    ? JSON.stringify(queryObject.states)
    : null;
  const cursor = searchParams.get("cursor");
  const searchPhrase = searchParams.get("searchPhrase");
  const documentsClientId = `docs-page-${pageSize}-${cursor}-${searchPhrase}`;
  // Results in infinite loop w/exhaustive deps
  // eslint-disable-next-line
  const listDocumentsFunc = useCallback(listDocuments, []);
  // eslint-disable-next-line
  const listEntitiesFunc = useCallback(listEntities, []);
  const docsToRender = documents.filter((doc) => pageDocumentIds.has(doc.id));
  const publishers = entities.filter((e) => e.roles.includes(Role.PUBLISHER));
  const states = getStatesUsa();
  const selectedPublisherIds = queryEntities
    .filter((e) => e.role === Role.PUBLISHER)
    .map((e) => e.id);
  const selectedStateIds = queryStates.map((s) => s.id);

  useEffect(() => {
    if (!hasSeenDocumentsClientId) {
      setHasSeenDocumentsClientId(
        activeListDocumentsFetches.has(documentsClientId)
      );
    }
  }, [documentsClientId, hasSeenDocumentsClientId, activeListDocumentsFetches]);

  useEffect(() => {
    if (!hasSeenEntitiesLoading && entitiesLoading) {
      setHasSeenEntitiesLoading(entitiesLoading);
    }
  }, [entitiesLoading, hasSeenEntitiesLoading]);

  useEffect(() => {
    if (
      loading &&
      hasSeenDocumentsClientId &&
      !activeListDocumentsFetches.has(documentsClientId) &&
      hasSeenEntitiesLoading &&
      !entitiesLoading
    ) {
      setHasSeenDocumentsClientId(false);
      setLoading(false);
    }
  }, [
    documentsClientId,
    hasSeenDocumentsClientId,
    loading,
    activeListDocumentsFetches,
    entitiesLoading,
    hasSeenEntitiesLoading,
  ]);

  useEffect(() => {
    setLoading(true);
    listDocumentsFunc(documentsClientId, {
      cursor: cursor ?? undefined,
      pageSize: `${pageSize}`,
      searchPhrase: searchPhrase ?? undefined,
      entities: entitiesJson ?? undefined,
      states: statesJson ?? undefined,
    });
  }, [
    cursor,
    pageSize,
    searchPhrase,
    documentsClientId,
    entitiesJson,
    statesJson,
    listDocumentsFunc,
  ]);

  useEffect(() => {
    setLoading(true);
    listEntitiesFunc(ENTITIES_CLIENT_ID);
  }, [listEntitiesFunc]);

  function onFilter(
    newSearchPhrase: string,
    newPublishers: string[],
    newStates: string[]
  ) {
    newSearchPhrase.trim()
      ? searchParams.set("searchPhrase", newSearchPhrase.trim())
      : searchParams.delete("searchPhrase");
    for (const [key] of Array.from(searchParams.entries())) {
      if (key.match(/^entities/)) {
        searchParams.delete(key);
      }
      if (key.match(/^states/)) {
        searchParams.delete(key);
      }
    }
    let entityIdx = 0;
    for (const publisherId of newPublishers) {
      searchParams.set(`entities[${entityIdx}][role]`, Role.PUBLISHER);
      searchParams.set(`entities[${entityIdx}][id]`, publisherId);
      entityIdx++;
    }
    let statesIdx = 0;
    for (const stateId of newStates) {
      searchParams.set(`states[${statesIdx}][id]`, stateId);
      statesIdx++;
    }
    setSearchParams(searchParams);
  }

  function changePage(cursor: string | null) {
    cursor ? searchParams.set("cursor", cursor) : searchParams.delete("cursor");
    setSearchParams(searchParams);
  }

  return (
    <DocumentsPageContext.Provider
      value={{
        changePage,
        currentUser,
        documents: docsToRender,
        error:
          documentsErrors[documentsClientId]?.message ??
          entitiesErrors[ENTITIES_CLIENT_ID]?.message,
        loading,
        nextCursor,
        offset,
        onFilter,
        pageSize,
        prevCursor,
        publishers,
        states,
        searchPhrase: searchPhrase ?? "",
        searchResultSnippets,
        selectedPublisherIds,
        selectedStateIds,
        total,
      }}
    >
      {props.children}
    </DocumentsPageContext.Provider>
  );
};

export const useDocumentsPageContext = () => useContext(DocumentsPageContext);
