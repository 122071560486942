import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DocumentsPage from "../DocumentsPage/DocumentsPage";
import DocumentPage from "../DocumentPage/DocumentPage";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DocumentsPage />} />
        <Route path="/documents" element={<DocumentsPage />} />
        <Route path="/documents/:id" element={<DocumentPage />} />
      </Routes>
    </BrowserRouter>
  );
}
