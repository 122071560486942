import React, { useState } from "react";
import { useCurrentUserContext } from "../../contexts/currentUser/currentUser";
import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  Input,
} from "@chakra-ui/react";

const EMAIL_REGEX =
  // eslint-disable-next-line
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

function isValid(email: string): boolean {
  return !!email.match(EMAIL_REGEX);
}

export default function UpdatesEmailForm() {
  const { user, addEmail } = useCurrentUserContext();
  const [valid, setValid] = useState(false);
  const [hasBlurred, setHasBlurred] = useState(false);
  const [email, setEmail] = useState(user?.email ?? "");

  return (
    <form onSubmit={() => addEmail(email)}>
      <Flex alignItems="end" direction={{ base: "column", md: "row" }} gap={3}>
        <FormControl>
          <Input
            onBlur={() => setHasBlurred(true)}
            isInvalid={!valid && hasBlurred}
            errorBorderColor="red.800"
            onChange={(v) => {
              setValid(isValid(v.target.value));
              setEmail(v.target.value);
            }}
            placeholder="edward.murrow@cbs.com"
            type="email"
          />
          {!valid && hasBlurred && (
            <FormHelperText>Please provide a valid email</FormHelperText>
          )}
        </FormControl>
        <Button
          colorScheme="purple"
          isDisabled={!valid}
          type="submit"
          mb={{ md: !valid && hasBlurred ? "27px" : "1px" }}
          width={{ base: "100%", md: "xs" }}
        >
          Subscribe
        </Button>
      </Flex>
    </form>
  );
}
