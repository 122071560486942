import { Card, CardBody, CardProps, Heading } from "@chakra-ui/react";
import React, { useState } from "react";
import { User } from "../../contexts/currentUser/currentUser";
import UpdatesEmailForm from "../UpdatesEmailForm/UpdatesEmailForm";

interface SignupCardProps extends CardProps {
  user: User;
}

export default function SignupCard(props: SignupCardProps) {
  const { user, ...cardProps } = props;
  const [initiallyLoggedIn] = useState(!!user && !!user.email);

  if (!user.email) {
    return (
      <Card {...cardProps}>
        <CardBody>
          <Heading mb={4} size="md">
            Subscribe for updates
          </Heading>
          <UpdatesEmailForm />
        </CardBody>
      </Card>
    );
  } else if (!initiallyLoggedIn) {
    return (
      <Card {...cardProps}>
        <CardBody>
          <Heading mb={3} size="md">
            You're in!
          </Heading>
          You'll receive updates in the future.
        </CardBody>
      </Card>
    );
  } else {
    return null;
  }
}
